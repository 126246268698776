<template>
  <div class="artistic-container">
    <!-- <div class="detail-index-crumbs-m" v-if="name">
      <span class="detail-index-crumbs-up">艺术品鉴</span> /
      <span class="detail-index-crumbs-this">{{name}}</span>
    </div> -->
    <div class="artistic-content">
      <div class="artistic-switch-type-pic">
          <img
            src="https://image.bookgo.com.cn/webculture/jm/artistic/artistic-pic.png"
            alt=""
          />
        </div>
      <div class="tab-secondNav" >
        <ul class="tab-secondNav-choose-item">
          <li
              v-for="(item, i) in secondNav"
              :key="i"
              :class="{ tabActivesecondNav: cur2 == item.id }"
              @click="changeSecondNav(item,i)"
          >
            {{ item.name }}
            <span :class="{tabActivesecondNavSpan: cur2 == item.id}">
                <span :class="{tabActivesecondNavSubSpan: cur2 == item.id}">

                </span>
            </span>
          </li>
        </ul>

      </div>
      <div class="artistic-switch-type" v-if="categoryList.length>0">
        
        <div class="artistic-switch-type-title">分类：</div>
        <div class="artistic-switch-type-content">
          <div
            class="artistic-switch-type-content-li"
            :class="{
              'artistic-switch-type-content-li-active': cur == item.id,'imgType':item.label_name.length > 4
            }"
            v-for="(item, index) in categoryList"
            :key="item.id"
            @click="handleChangeValue(item.id)"
          >
            {{ item.label_name }}
          </div>
        </div>
      </div>

      <div class="artistic-count-info">
        共{{ totalPage }}页 ｜ {{ total }}条数据
      </div>

      <div class="artistic-switch-content" v-if="content.length != 0">
        <div
          class="artistic-switch-content-li"
          v-for="(item, index) in content"
          :key="index"
          @click="toNewPage(item)"
        >
          <div class="interact-index-container-FAQ-content-headPic">
            <img
              style="width: 100%; height: 100%"
              src="https://image.bookgo.com.cn/webculture/jm/border-top.png"
              alt=""
            />
          </div>
          <div class="interact-index-container-FAQ-content-footerPic">
            <img
              style="width: 100%; height: 100%"
              src="https://image.bookgo.com.cn/webculture/jm/border-bottom.png"
              alt=""
            />
          </div>
          <div class="interact-index-container-FAQ-content-headPic-active">
            <img
              style="width: 100%; height: 100%"
              src="https://image.bookgo.com.cn/webculture/jm/border-top-active.png"
              alt=""
            />
          </div>
          <div class="interact-index-container-FAQ-content-footerPic-active">
            <img
              style="width: 100%; height: 100%"
              src="https://image.bookgo.com.cn/webculture/jm/border-bottom-active.png"
              alt=""
            />
          </div>
          <div class="artistic-switch-content-li-left">
            <img style="width: 100%; height: 100%" :src="item.cover == ''?'https://image.bookgo.com.cn/image/defaultM.jpg':item.cover" alt="" />
          </div>
          <div class="artistic-switch-content-li-right">
            <div class="artistic-switch-content-li-right-title">
              {{ item.title }}
            </div>
            <div class="artistic-switch-content-li-right-des">
              {{ item.summary | deleteHtmlTag }}
            </div>
            <div class="artistic-switch-content-li-right-time">
              时间：{{ item.publish_time }}
            </div>
          </div>
        </div>
        <!-- 分页组件 -->
        <pagination
          :total="total"
          :page.sync="page"
          :limit.sync="limit"
          @pagination="getArtisticList(cat_id)"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import { getCateList, getActivityList } from "@/api/activity/activity";
import { list, cateList, getpid } from "@/api/article";
import moment from "moment";
import Pagination from "@/components/Pagination";
import ListItem from "@/views/components/listItem";
import {navChildList} from "@/api/header/header";
export default {
  data() {
    return {
      listLoading: true,
      total: 0,
      page_count: 0,
      page: 1,
      limit: 6,
      aid: process.env.VUE_APP_AID,
      id: "",
      cat_id: "",
      cur: 0,
      content: [],
      categoryList: [],
      status: "",
      title:"",
      name:"",
      secondNav:[],
      cur2:0,
    };
  },
  components: {
    Pagination,
  },
  watch: {
    $route(newV) {
      window.scroll(0, 0);
      this.page = 1;
      this.cur = 0;
      this.id = newV.query.id;
      this.column_id = newV.query.column_id;
      this.cid = newV.query.cid;
      if (this.cid) {
        this.cur2 = this.cid;
      }
      this.secondNav = [];
      this.categoryList = [];
      this.getArtisticList();
      this.getChildNav();
    },
  },
  created() {},
  mounted() {
    window.scroll(0, 0);
    this.page = 1;
    this.id = this.$route.query.id;
    this.cid = this.$route.query.cid;
    this.column_id = this.$route.query.column_id;
    if (this.cid) {
      this.cur2 = this.cid;
    }
    this.getChildNav();
    this.getArtisticList();
  },
  computed: {
    totalPage() {
      return Math.ceil(this.total / this.limit);
    },
  },
  methods: {
    changeSecondNav(item) {
      //获取导航下的分类
      if (item.url_type == 1 && item.url) {
        window.open(item.url, "_blank");
      } else {
        if (item.content_id == 0) {
          this.$router.push(
                `/${item.tpl_code}?id=${this.$route.query.id}&cid=${item.id}&column_id=${item.column_id}`
              );
          //   let routeUrl = this.$router.resolve({
          //   path: `/${item.tpl_code}`,
          //   query: {
          //     id: this.$route.query.id,
          //     cid: item.id,
          //     column_id: item.column_id,
          //   },
          // });
          // window.open(routeUrl.href, "_blank");
        } else {
          this.$router.push(
                `/${item.tpl_code}/detail?tid=${item.content_id}&id=${this.$route.query.id}&cid=${item.id}&column_id=${item.column_id}`
              );
          // let routeUrl = this.$router.resolve({
          //   path: `/${item.tpl_code}/detail`,
          //   query: {
          //     tid: item.content_id,
          //     id: this.$route.query.id,
          //     cid: item.id,
          //     column_id: item.column_id,
          //   },
          // });
          // window.open(routeUrl.href, "_blank");
        }
      }
    },
    getChildNav() {
      let params = {
        aid: this.aid,
        pid: this.$route.query.id,
        app_type: 1,
      };

      navChildList(params).then((res) => {
        console.log(res);
        // this.secondNav = res.data
        if (res.code == 0) {
          this.secondNav = res.data;
        //   console.log("secondNav", this.secondNav);
        }
      });
    },
    handleChangeValue(id) {
      this.page = 1;
      this.cur = id;
      this.getArtisticList();
    },
    getArtisticList() {
      let params = {
        aid: this.aid,
        page: this.page,
        page_size: this.limit,
        app_type: 1,
        column_id: this.column_id,
      };
      if (parseInt(this.cur) > 0) {
        params.label_id = this.cur;
      }
      console.log(params);

      this.listLoading = true;
      list(params).then((res) => {
        console.log(res);
        this.listLoading = false;
        this.content = res.data.list;
        res.data.list.forEach((item) => {
          item.publish_time = moment(item.publish_time * 1000).format(
              "YYYY-MM-DD"
          );
        });
        if (res.data.label_list.length > 0) {
          this.categoryList = res.data.label_list;
          this.categoryList.unshift({
            id: 0,
            label_name: "全部",
          });
        }
        this.total = res.data.count;
      });
    },
    toNewPage(item) {
      if (item.is_link == 1 && item.jump_url) {
        window.open(item.jump_url, "_blank");

      } else {
        let routeUrl = this.$router.resolve({
          path: `${item.tpl_code}/detail`,
          query: { tid:item.id,id:this.$route.query.id,cid:this.$route.query.cid,column_id:this.$route.query.column_id },
        });
        window.open(routeUrl.href, "_blank");
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/cmsCommon_image_index.scss";
.detail-index-crumbs-m {
    display: flex;
    align-items: center;
    font-size: 0.14rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    line-height: 0.2rem;
    margin-bottom: 0.28rem;
    width: 12rem;
    margin: auto;
    padding-top: 0.3rem;
}
.detail-index-crumbs-up {
    color: #273143;
    margin-right: 0.1rem;
}

.detail-index-crumbs-this {
    color: #761f1e;
    margin-left: 0.1rem;
}
.partyBuilding-container-bread{
    padding-top: 0.3rem;
}
.tab-secondNav-choose-item{
  font-size: 0.17rem;
  border-bottom: 1px solid #D5D5D5;
  padding-bottom: 0.14rem;
}
.tab-secondNav-choose-item{
  display: flex;
}
.tab-secondNav-choose-item li{
  margin-left: 10px;
}
.tab-secondNav-choose-item li{
  cursor: pointer;
}
.tab-secondNav-choose-item li:not(:first-child){
  cursor: pointer;
  margin-left: 0.46rem;
}
.tabActivesecondNav{
  color: #761F1E;
  position: relative;
}

.tabActivesecondNavSpan{
  width: 100%;
  height: 3px;
  display: inline-block;
  position: absolute;
  bottom: -0.14rem;
  left: 0rem;
}
.tabActivesecondNavSubSpan{
  width: 80%;
  background: #991C10;
  height: 3px;
  display: inline-block;
  position: absolute;
  animation: 0px;
  margin: auto;
  left: 0px;
  right: 0px;
}
.tab-subSwitch{
  margin-top: 0.33rem;
}
.news-container{
  padding-top: 0.38rem;
}
</style>
